.articleClass {
  * {
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
  }

  table {
    width: 100%;
    border-collapse: separate;
    font-family: 'Arial', sans-serif;
    font-size: 14px;

    tbody {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #dde3ed;
        border-radius: 8px;
        pointer-events: none;
      }
    }

    th {
      text-align: left;
      padding: 6px;
      font-weight: 600;
      font-size: 14px;
      color: #767676;
      background-color: #f9fafb;
      text-transform: uppercase;
    }

    td {
      padding: 16px;
      font-size: 14px;
      line-height: 1.6;
      color: black;
      border-bottom: 1px solid #E2E8F0;
      vertical-align: top;
    }

    tr:nth-child(even) {
      background-color: #f8fafc;
    }

    tr:hover {
      background-color: #edf2f7;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    font-family: 'Arial', sans-serif;
    font-size: 14px;

    tbody {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #dde3ed;
        border-radius: 8px;
        pointer-events: none;
      }
    }

    th {
      text-align: left;
      padding: 6px;
      font-weight: 600;
      font-size: 14px;
      color: #767676;
      background-color: #f9fafb;
      text-transform: uppercase;
    }

    td {
      padding: 16px;
      font-size: 14px;
      line-height: 1.6;
      color: black;
      border-bottom: 1px solid #E2E8F0;
      vertical-align: top;
    }

    tr:nth-child(even) {
      background-color: #f8fafc;
    }

    tr:hover {
      background-color: #edf2f7;
    }
  }


  img {
    margin-top: 20px;
  }

  strong {
    font-weight: 600;
  }

  h1 {
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 28px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p, li {
    line-height: 25px;
    margin-bottom: 4px;
    margin-top: 4px;
    font-family: var(--font);
    font-size: 14px;
    &::marker {
      color: var(--bullet);
    }
  }

  img {
    max-width: 100%;
  }

  blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    position: relative;
    border-radius: 7px;
    background: var(--accentBlue);
    margin-bottom: 20px;
    padding: 2px 2px 6px;

    > * {
      padding: 40px 60px;
      background: var(--white);
      border-radius: 7px;
      margin: 0;

    }

    &:after {
      position: absolute;
      top: 20px;
      left: 20px;
      color: var(--accentBlue);
      width: 20px;
      font-family: 'britannic bold';
      font-weight: 500;
      height: 20px;
      font-size: 15px;
      border: 1.5px solid var(--accentBlue);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      content: 'i';

    }
  }

  h2[id] {
    display: none;
  }
  hr + p {
    display: none;
  }

}
