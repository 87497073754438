.btn {
    background: var(--deepBlue);
    color: var(--white);
    font-size: 14px;
    &.btn {
        font-family: var(--font);
    }
    padding: 12px 23px;
    border: none;
    cursor: pointer;
    text-transform: none;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0079A1;
    }

    &:disabled {
        background-color: #0088B5;
        color: #FFFFFF;
        opacity: 0.5;
    }
}
