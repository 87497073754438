.wrapper {
    margin-top: 66px;
    margin-bottom: 58px;

    .title {
        margin-bottom: 20px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 90px;
        grid-row-gap: 48px;
    }
}
