.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 48px auto 68px;

    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--black);
        font-family: var(--font);
        letter-spacing: 0.9px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
}
