.wrapper {
    .imgWrapper {
        border: 1px solid var(--borderGrey);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: -20px 20px;
        overflow: hidden;
        border-radius: 8px;
        min-height: 104px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        .preview {
            overflow: hidden;
            box-sizing: border-box;
            padding: 10px;
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100%);
            height: 100%;
            background-color: rgba(13, 11, 11, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--previewTextColor);
            font-size: 14px;
            line-height: 1.4;
            font-weight: normal;
            font-family: var(--font);
            text-align: center;
            cursor: pointer;
            transform: translateY(100%);
            transition: transform 0.3s ease;
        }

        &:hover {
            .preview {
                transform: translateY(0%);
            }
        }
    }

    .img {
        height: 66px;
    }

    .description {
        display: none;
    }

    .link {
        margin-top: 12px;
        display: block;
        text-decoration: none;
        font-size: 14px;
        font-style: var(--font);
        color: var(--linkColor);
        font-weight: 600;
    }
}
