.header {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 133px;
    background-color: var(--deepBlue);
    color: var(--white);
    font-weight: normal;
    font-family: var(--font);
    position: static;

    .title {
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 48px;
        text-transform: uppercase;
    }

    .subTitle {
        font-size: 14px;
    }

    &.headerFixed {
        transition: 2s;
        z-index: 1000;
        position: fixed;
        top: 0;
        width: calc(100% - 16px);
        left: 50%;
        transform: translateX(-50%);
        padding-top: 24px;
        padding-bottom: 24px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 8px;
            background-color: var(--white);
            z-index: 999;
        }

        .subTitle {
            margin-bottom: 16px;
        }

        .breadcrumbsWrapper {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            text-align: left;
            background-color: #fff;

            a {
                display: flex;
                align-items: center;
                gap: 5px;
            }

            .breadcrumbs {
                display: flex;
                align-items: center;
                gap: 5px;
                margin-bottom: 10px;
                color: #000;
            }
        }
    }
}
