.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12vh;

    .title {
        margin-top: 48px;
        margin-bottom: 20px;
        font-family: var(--font);
        font-weight: 600;
        font-size: 30px;
        letter-spacing: 0.09em;
        color: var(--blackText);
        text-align: center;
    }

    .description {
        font-family: Roboto;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.09em;
        text-align: center;
        color: var(--descriptionGrey);
        line-height: 1.36;
        margin-bottom: 20px;
    }

    .btnLink {
        display: block;
        background: var(--deepBlue);
        color: var(--white);
        padding: 10px 16px;
    }
}
