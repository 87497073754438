.wrapper {
    text-align: center;
    padding: 22px 14px;
    transition: box-shadow 0.5s ease;

    &:hover {
        background-color: var(--white);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .iconWrapper {
            // --hover-color: var(--deepBlue);
            --hover-color: #009bcf88;
        }
    }

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 68px;
        --hover-color: transparent;

        svg {
            width: 70px;
        }
    }

    .title {
        color: var(--black);
        font-size: 14px;
        font-weight: 600;
        font-family: var(--font);
        margin-bottom: 10px;
        margin-top: 11px;
        letter-spacing: 0.9px;
    }

    .description {
        font-size: 14px;
        line-height: 1.5;
        color: var(--descriptionGrey);
        max-width: 202px;
        margin: 0 auto;
    }
}
