.wrapper {
    .title {
        margin-bottom: 32px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 2fr);
        grid-column-gap: 90px;
        grid-row-gap: 120px;
    }
}
